import { Icon } from './core/models/icon.model';

type IconRegister =
  | string
  | {
      name: string;
      variants: string[];
    };

const registerIcons = ({ baseUrl, iconRegisters, ext = 'svg' }: { baseUrl: string; iconRegisters: IconRegister[]; ext?: string }) => {
  const transformIcon = (baseUrl: string) => (iconRegister: IconRegister) => {
    if (typeof iconRegister === 'string') {
      return { name: iconRegister, url: `${baseUrl}/${iconRegister}.${ext}` } as Icon;
    }
    return iconRegister.variants.map((variant) => ({
      name: `${iconRegister.name}--${variant}`,
      url: `${baseUrl}/${variant}/${iconRegister.name}.${ext}`,
    })) as Icon[];
  };
  return iconRegisters.map(transformIcon(baseUrl)).flat();
};

export const ICON_REGISTRIES = registerIcons({
  baseUrl: '../assets/icons/svgs',
  iconRegisters: [
    'ats_sort_icon',
    'ats_delete_icon',
    'aso_sort_icon',
    'pwa_upload_icon',
    'chrome_icon',
    'notice_notes',
    'report_calendar',
    'chart-line-black',
    'chart-line-white',
    'chart-bar-black',
    'chart-bar-white',
    'chart-stacked-black',
    'chart-stacked-white',
    'star_fill_icon',
    'star_line_icon',
    'mail_opened',
    'open_link',
    'file_download',
    'file_upload',
    'sns_ig_icon',
    'ig_integration_white',
    'ig_integration',
    'fb_integration',
    'linked_account',
    'fb_transparent',
    'download_icon',
    'download_icon_grey',
    'menu_grid',
    'menu_grid_active',
    'menu_list',
    'menu_list_active',
    'check',
    'cross_error',
    'exterior_duplicate',
    'exterior_trash',
    'more_horiz',
    'empty-report-list',
    'draggable',
    'chevrons-right',
    'exterior_preview_desktop',
    'exterior_preview_desktop_active',
    'exterior_preview_mobile',
    'exterior_preview_mobile_active',
    'game_script',
    'clock_icon',
    'check_blue_icon',
    'warning',
    'light_theme',
    'light_theme_active',
    'dark_theme',
    'dark_theme_active',
    'imgClose',
    'imgReload',
    'imgResize_minimize',
    'imgResize_maximize',
    'imgDrag',
    'cwv_vector',
    'sns_overview',
    'sns_audience',
    'sns_posts',
    'user',
    'sign_out',
    'check_empty',
    'cancel_icon',
    'customize',
    'integration',
    'am_icon_logo',
    'truvid_icon_logo',
    'gliacloud_icon_logo',
    'select_media_icon_logo',
    'edit-chat',
    'chevron_right',
    'chevron_left',
    'dock_to_right',
    'close_dock',
    'ai_icon',
    'ai_assistant',
    'red_warning',
    'info',
  ],
});

export const SIDE_MENU_ICON_REGISTRIES = registerIcons({
  baseUrl: '../assets/icons/side-menu',
  iconRegisters: [
    'menu_group_analytics',
    'menu_group_engage',
    'menu_group_help_page',
    'menu_group_monetize',
    'menu_group_payment_process',
    'menu_group_settings',
    'menu_group_growth',
    'analytics_dashboard',
    'analytics_report',
    'analytics_aso',
    'analytics_sns_analytics',
    'monetize_ats',
    'monetize_game',
    'monetize_upr',
    'monetize_admob_mediation',
    'monetize_vcpm_grey',
    'monetize_video_generator',
    'engage_pwa',
    'engage_web_stories',
    'engage_dmp',
    'build_ampcms',
    'settings_account',
    'settings_ad_units',
    'settings_apps',
    'settings_change_history',
    'settings_import',
    'settings_integration',
    'settings_mapping',
    'settings_sites',
    'settings_user_list',
    'payment_exchange_rate',
    'payment_payment',
    'payment_invoice',
    'menu_group_ai_assistant',
  ],
});
